import { Box, IconButton, Link, Stack, styled, Typography } from '@mui/material';
import { FOOTER_HEIGHT_LG, FOOTER_HEIGHT_MD } from 'pages/home/constants';
import { CloudflareIcon, MoonpayIcon, PolygonLogo } from 'shared/assets';
import { COLOR_ACCENT, COLOR_BG, COLOR_BLACK, COLOR_WH } from 'shared/config/theme/colors';
import { FontWeights } from 'shared/config/theme/Typography';
import { useIsDownLayoutMd } from 'shared/hooks';
import { useTranslation } from 'react-i18next';

import { Web3WedLogo } from '../Icons';

import { LINKS, PAY_SERVICES, SOCIALS } from './Footer.helper';

const SocialsWrapper = styled(Stack)(({ theme }) => ({
  paddingBottom: FOOTER_HEIGHT_LG,
  marginBottom: 120,
  marginTop: 76,

  [theme.breakpoints.down('md')]: {
    paddingBottom: FOOTER_HEIGHT_MD,
    marginBottom: 60,
    marginTop: 40,
  },
}));

const FooterWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: COLOR_WH,
  height: FOOTER_HEIGHT_LG,
  width: '100%',
  position: 'absolute',
  bottom: 0,
  left: 0,
  zIndex: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  [theme.breakpoints.down('md')]: {
    height: FOOTER_HEIGHT_MD,
  },
}));
const FooterRow = styled(Stack)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
  },
}));

const IconsRow = styled(Stack)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexDirection: 'row',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const SocialButton = styled(IconButton)({
  width: 56,
  height: 56,
  background: 'transparent',
  border: `1px solid ${COLOR_ACCENT}`,
  borderRadius: '50%',
  svg: { color: COLOR_ACCENT },
  '&:hover': {
    background: COLOR_ACCENT,
    svg: { color: COLOR_BG },
  },
});

export const Footer = () => {
  const isDownMdLayout = useIsDownLayoutMd();

  const { t, i18n } = useTranslation();
  return (
    <>
      <SocialsWrapper>
        <Stack direction="row" gap={2}>
          {SOCIALS.map((social) => {
            return (
              <Link key={social.url} href={social.url} target="_blank" rel="noreferrer">
                <SocialButton>{social.icon}</SocialButton>
              </Link>
            );
          })}
        </Stack>
      </SocialsWrapper>


      <FooterWrapper>
        <Stack
            sx={{
              mx: 'auto',
              maxWidth: '1170px',
              width: '100%',
              px: { xs: 1.5, md: 3 },
              mb: { xs: 3, sm: 5 },
            }}
        >

          <Stack direction={isDownMdLayout ? 'column' : 'row'} justifyContent="space-between" gap={6}>
            <FooterRow></FooterRow>
            <FooterRow gap={6} sx={{justifyContent: 'space-around'}}>
              <IconsRow>
                <Stack direction="row" gap={4} sx={{minWidth: '450px', justifyContent: 'center'}}>
                  {LINKS.map((link) => {
                    return (
                        <Link
                            href={link.url}
                            key={link.text}
                            target="_blank"
                            rel="noreferrer"
                            sx={{
                              fontWeight: FontWeights.SemiBold,
                              fontSize: 16,
                              lineHeight: 2,
                              color: COLOR_BLACK,
                              textDecoration: 'none',
                              textAlign: isDownMdLayout ? 'center' : 'start',
                            }}
                        >
                          {link.text}
                        </Link>
                    );
                  })}
                </Stack>
              </IconsRow>
            </FooterRow>
            <FooterRow gap={3}>
              <IconsRow gap={2.25}>
                <Stack direction="row" gap={2} sx={{fontWeight: FontWeights.SemiBold}}>
                  {t('main_Footer_Accept')}
                </Stack>
              </IconsRow>
            </FooterRow>
          </Stack>
        </Stack>
        <Stack
          sx={{
            mx: 'auto',
            maxWidth: '1170px',
            width: '100%',
            px: { xs: 1.5, md: 3 },
            mb: { xs: 3, sm: 5 },
          }}
        >

          <Stack direction={isDownMdLayout ? 'column' : 'row'} justifyContent="space-between" gap={6}>
            <FooterRow sx={{justifyContent: 'center'}}>
              <Web3WedLogo />
            </FooterRow>
            <FooterRow gap={6} sx={{justifyContent: 'space-around'}}>
              <IconsRow gap={3}>
                <Box>
                  <img src={CloudflareIcon} alt="Cloudflare" width='150px' />
                </Box>
                <Box>
                  <img src={PolygonLogo} alt="Polygon" width='150px' />
                </Box>
                <Box>
                  <img src={MoonpayIcon} alt="Moonpay" width='150px' />
                </Box>
              </IconsRow>
            </FooterRow>
            <FooterRow gap={3}>
              <IconsRow>
                <Stack direction="column" gap={1}>
                  {PAY_SERVICES.slice(0).map((payment) => {
                    return (
                        <Box key={payment.name}>
                          <img src={payment.url} alt={payment.name} width={75}/>
                        </Box>
                    );
                  })}
                </Stack>
              </IconsRow>
            </FooterRow>
          </Stack>
        </Stack>
        {/*<Link mt={5} href="mailto:hello@web3wed.io" textAlign="center" underline="none">
          <Typography
            color={COLOR_BLACK}
            variant="button"
            fontWeight={FontWeights.SemiBold}
            fontSize={16}
            sx={{
              textDecoration: 'underline',
            }}
          >
            hello@web3wed.io
          </Typography>
        </Link>*/}
        <Box px={3}>
          <Typography color={COLOR_BLACK} className="center" mt={4}>
            COPYRIGHT 2024 W3BNDZ LLC.
            {isDownMdLayout ? <br /> : ' '}
            {t('main_Footer_Copyright')}
            <br />
            {t('main_Footer_Copyright_Address')}
          </Typography>
        </Box>
      </FooterWrapper>
    </>
  );
};
