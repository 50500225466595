import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { Backdrop } from '@mui/material';
import { logoImage } from 'shared/assets';

const LoaderContext = createContext(undefined);

export const LoaderProvider = ({ children }: PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loading = setTimeout(() => setIsLoading(false), 2000);
    return () => clearTimeout(loading);
  }, []);

  return (
    <LoaderContext.Provider value={undefined}>
      {isLoading ? (
        <Backdrop open={isLoading} sx={{ zIndex: 99, background: 'transparent', transition: 'all 0.5s ease-out' }}>
          <img src={logoImage} width={206} height={155} alt="loader" />
        </Backdrop>
      ) : (
        children
      )}
    </LoaderContext.Provider>
  );
};
